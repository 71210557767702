import React from 'react';
import {useEffect, useState} from 'react';
import '../App.css';
import { TextField, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//Hur ofta stripsen ska skicka signaler. Mellan varannan minut till en gång per dygn. Man ska kunna skriva in tid som fritext

const ReportInterval = (props) => {

  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();

  const handleHours = (hours) => {
    if(hours >= 1){
      setHours(hours);
    }
    else {
      setHours(undefined);
    }
  }

  const handleMinutes = (minutes) => {
    setMinutes(minutes);
  }

  const checkIfPositiveNumber = (hours, minutes) => {
    if (hours === 0 && minutes === 0){
      setMinutes(undefined);
      setHours(undefined);
      props.setSpreadingFactor(undefined);
    }
  }

  useEffect(() => {
    checkIfPositiveNumber(hours, minutes);
    let totalTime_s = 0;

    const hours_s = hours * (60 * 60);
    const minutes_s = minutes * 60;
  
    if (!hours_s){
      totalTime_s = minutes_s;  
    }
    else if (!minutes_s){
      totalTime_s = hours_s;
    }
    else{
      totalTime_s = hours_s + minutes_s;
    }

    let reports_per_h = 3600 / totalTime_s;
    //console.log({reports_per_h}) 
    props.onTimeChanged(reports_per_h);
 
  }, [hours, minutes]);

  return (
  <>
    <div className="ReportIntervalContainer">
      
      <TimeHeader profile={props.profile}/>
      {props.profile === "periodic" &&
        <TextField 
          id="standard-basic" 
          label="Hours" 
          variant="standard" 
          color="primary" 
          type="number"
          placeholder="maximum 24h"
          defaultValue="1"
          InputProps={{ disableUnderline: true }}
          sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            input: {
              color: "primary.main"
            },
            label: {
              color: "primary.main",
            }
          }}
          onChange={(event) => {handleHours(event.target.value) }}
        />
      }

      {props.profile !== "periodic" && 
        <TextField 
          id="standard-basic" 
          label="Hours" 
          variant="standard" 
          color="primary" 
          type="number"
          placeholder="maximum 24h"
          InputProps={{ disableUnderline: true }}
          sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            input: {
              color: "primary.main"
            },
            label: {
              color: "primary.main",
            }
          }}
          onChange={(event) => {handleHours(event.target.value) }}
        />
      }
        
      <TextField 
        id="standard-basic" 
        label="Minutes" 
        variant="standard" 
        color="primary" 
        type="number"
        min="0"
        placeholder="minimum 2 min"
        InputProps={{ disableUnderline: true }}
        sx={{
          borderBottom: 1,
          borderColor: 'primary.main',
          input: {
            bgColor: 'primary.main',
            color: "primary.main",
          },
          label: {
            color: "primary.main",
          },
        }}
        onChange={(event) => {handleMinutes(event.target.value)}}
      />
    </div>
  </>
  );
}

  const TimeHeader = (props) => {
    return(
      <>
      <header className='App'>
        <span>Report interval&nbsp;</span>

        <Tooltip title='Please enter how often you want the product to send data'>
          <InfoOutlinedIcon
            fontSize="small"
            color="primary"  
          />
        </Tooltip>  
        
      </header> 
      {props.profile === ("periodic") &&
        <p className='Undertitle'>The default value is<br /> 1 report per hour</p>  
      }
      {props.profile !== ("periodic") &&
        <p className='Undertitle'>This section is optional and will revert to<br />the default value if nothing is chosen.</p>
      }
      </>
    )
  };

export default ReportInterval;